const BASE_URL = 'https://everlearningai-v4ugw6p6aq-od.a.run.app';

export const fetchWithAuth = async (endpoint, options = {}) => {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
    ...options.headers,
  };

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      ...options,
      headers,
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/signin';
      }
      const data = await response.json();
      const error = new Error(data.detail || `HTTP error! status: ${response.status}`);
      error.status = response.status;
      error.data = data;
      throw error;
    }

    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const signUp = async (userData) => {
  const response = await fetch(`${BASE_URL}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });

  const data = await response.json();

  if (!response.ok) {
    const error = new Error(data.detail || `HTTP error! status: ${response.status}`);
    error.status = response.status;
    error.data = data;
    throw error;
  }

  return data;
};

export const signIn = async (email, password) => {
  const response = await fetch(`${BASE_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      username: email,
      password: password,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    const error = new Error(data.detail || `HTTP error! status: ${response.status}`);
    error.status = response.status;
    error.data = data;
    throw error;
  }
  return data;
};

export const createAgent = async (agentData) => {
  console.log('Sending agent data:', agentData);
  try {
    const response = await fetchWithAuth('/agents', {
      method: 'POST',
      body: JSON.stringify(agentData),
    });
    console.log('Server response:', response);
    return response;
  } catch (error) {
    console.error('Error in createAgent:', error);
    throw error;
  }
};

export const connectSlack = async (agentId) => {
  if (!agentId) {
    throw new Error('Agent ID is required to connect to Slack');
  }
  return fetchWithAuth(`/connect-slack?agent_id=${encodeURIComponent(agentId)}`);
};

export const completeSlackInstallation = async (code, state) => {
  return fetchWithAuth(`/auth/slack/callback?code=${encodeURIComponent(code)}&state=${encodeURIComponent(state)}`);
};

//export const completeSlackInstallation = async (code, state) => {
//  const response = await fetchWithAuth(`/auth/slack/callback?code=${encodeURIComponent(code)}&state=${encodeURIComponent(state)}`);
  
//  if (response.status !== 'success') {
//    throw new Error(response.message || 'Failed to complete Slack installation');
//  }

//  return response; // Return the entire response object
//};


export const connectGoogleDrive = async (agentId) => {
  if (!agentId) {
    throw new Error('Agent ID is required to connect Google Drive');
  }
  return fetchWithAuth(`/connect-google-drive?agent_id=${encodeURIComponent(agentId)}`);
};

export const completeGoogleDriveAuth = async (code, state) => {
  return fetchWithAuth(`/auth/google/callback?code=${encodeURIComponent(code)}&state=${encodeURIComponent(state)}`);
};

export const getGoogleDriveFolders = async () => {
  return fetchWithAuth('/google-drive/folders');
};

export const checkGoogleDriveConnection = async () => {
  return fetchWithAuth('/check-google-drive-connection');
};

export const uploadFiles = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/upload-files`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    });

    const data = await response.json();

    if (!response.ok) {
      const error = new Error(data.detail || `HTTP error! status: ${response.status}`);
      error.status = response.status;
      error.data = data;
      throw error;
    }

    return data;
  } catch (error) {
    console.error('File upload failed:', error);
    throw error;
  }
};

export const deployAgent = async (agentId, workspaceId) => {
  if (!agentId || !workspaceId) {
    throw new Error('Agent ID and Workspace ID are required to deploy agent');
  }
  return fetchWithAuth(`/agents/${agentId}/install-to-slack`, {
    method: 'POST',
    body: JSON.stringify({ workspace_id: workspaceId }),
  });
};

export const getAgents = async () => {
  return fetchWithAuth('/agents');
};

export const deleteAgent = async (agentId) => {
  if (!agentId || typeof agentId !== 'string') {
    throw new Error('Invalid agent ID');
  }
  
  try {
    const response = await fetchWithAuth(`/agents/${agentId}`, {
      method: 'DELETE',
    });
    
    if (response && typeof response === 'object' && response.message === "Agent deleted") {
      return response;
    } else {
      throw new Error('Unexpected response from server');
    }
  } catch (error) {
    console.error('Error deleting agent:', error);
    throw error;
  }
};

export const getAgentTemplates = async () => {
  return fetchWithAuth('/agent-templates');
};

export const getAgentTemplate = async (templateId) => {
  return fetchWithAuth(`/agent-templates/${templateId}`);
};

export const getKnowledgeBases = async () => {
  return fetchWithAuth('/knowledge-bases');
};

export const createKnowledgeBase = async (knowledgeBaseData) => {
  return fetchWithAuth('/knowledge-bases', {
    method: 'POST',
    body: JSON.stringify(knowledgeBaseData),
  });
};

export const updateKnowledgeBase = async (knowledgeBaseId, updateData) => {
  return fetchWithAuth(`/knowledge-bases/${knowledgeBaseId}`, {
    method: 'PUT',
    body: JSON.stringify(updateData),
  });
};

export const deleteKnowledgeBase = async (knowledgeBaseId) => {
  const id = typeof knowledgeBaseId === 'object' && knowledgeBaseId.$oid 
    ? knowledgeBaseId.$oid 
    : knowledgeBaseId;
  console.log('Deleting knowledge base with ID:', id);
  return fetchWithAuth(`/knowledge-bases/${id}`, {
    method: 'DELETE',
  });
};

export const linkKnowledgeBaseToAgent = async (agentId, knowledgeBaseId) => {
  const agentIdString = typeof agentId === 'object' && agentId.$oid ? agentId.$oid : agentId;
  const kbIdString = typeof knowledgeBaseId === 'object' && knowledgeBaseId.$oid ? knowledgeBaseId.$oid : knowledgeBaseId;
  
  return fetchWithAuth(`/link-knowledge-base?agent_id=${agentIdString}&knowledge_base_id=${kbIdString}`, {
    method: 'POST',
  });
};