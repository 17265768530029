import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { uploadFiles, getKnowledgeBases, deleteKnowledgeBase, linkKnowledgeBaseToAgent } from '../../utils/api';
import { ChevronDown, ChevronUp, MoreVertical, Trash2, Plus } from 'react-feather';

const KnowledgeBaseItem = ({ base, isSelected, onSelect, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const handleToggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleToggleConfig = (e) => {
    e.stopPropagation();
    setIsConfigOpen(!isConfigOpen);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(base._id);
    setIsConfigOpen(false);
  };

  const handleItemClick = () => {
    onSelect(base._id);
  };

  return (
    <div
      className={`bg-[#1f1f1f] p-4 rounded-lg flex-shrink-0 relative cursor-pointer ${
        isSelected ? 'border-2 border-[#4bb35d]' : ''
      }`}
      style={{ 
        width: '300px', 
        minHeight: '200px',
        height: isExpanded ? 'auto' : '200px',
        transition: 'border-color 0.2s ease-in-out, height 0.3s ease-in-out' 
      }}
      onClick={handleItemClick}
    >
      <div className="flex justify-between items-start mb-2">
        <div className="flex-grow">
          <h3 className="text-xl font-bold">{base.name}</h3>
          <p className="text-sm opacity-80">{base.description}</p>
        </div>
        <div className="relative">
          <button
            onClick={handleToggleConfig}
            className="text-gray-400 hover:text-white transition duration-300 ease-in-out"
            type="button"
          >
            <MoreVertical size={20} />
          </button>
          {isConfigOpen && (
            <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-[#2f2f2f] ring-1 ring-black ring-opacity-5">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <button
                  onClick={handleDeleteClick}
                  className="block px-4 py-2 text-sm text-white hover:bg-[#3f3f3f] w-full text-left"
                  role="menuitem"
                >
                  <Trash2 size={16} className="inline mr-2" />
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <button
        onClick={handleToggleExpand}
        className="text-[#4bb35d] hover:text-[#45a456] transition duration-300 ease-in-out flex items-center"
        type="button"
      >
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        <span className="ml-2">{isExpanded ? 'Hide' : 'Show'} Details</span>
      </button>
      {isExpanded && (
        <div className="mt-4">
          <h4 className="font-semibold mb-2">Documents:</h4>
          {base.user_documents && base.user_documents.length > 0 ? (
            <ul className="list-disc list-inside">
              {base.user_documents.map((doc, index) => (
                <li key={index} className="text-sm">
                  {doc}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-sm italic">No documents available</p>
          )}
        </div>
      )}
    </div>
  );
};

const CreateKnowledgeBaseItem = ({ onClick, isSelected }) => {
  return (
    <div
      className={`bg-[#1f1f1f] p-4 rounded-lg flex-shrink-0 relative cursor-pointer hover:bg-[#2f2f2f] transition-colors duration-300 ${
        isSelected ? 'border-2 border-[#4bb35d]' : ''
      }`}
      style={{ width: '300px', height: '200px' }}
      onClick={onClick}
    >
      <div className="flex flex-col items-center justify-center h-full">
        <Plus size={48} className="text-[#4bb35d] mb-4" />
        <h3 className="text-xl font-bold text-center">Create New Knowledge Base</h3>
        <p className="text-sm opacity-80 text-center mt-2">Upload files to create a new knowledge base</p>
      </div>
    </div>
  );
};

const FileUploadForm = () => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [agentId, setAgentId] = useState(null);
  const [selectedTools, setSelectedTools] = useState([]);
  const [fileValidationErrors, setFileValidationErrors] = useState([]);
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState(null);
  const [isDeletingKnowledgeBase, setIsDeletingKnowledgeBase] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const MAX_FILES = 50;
  const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB in bytes
  const SUPPORTED_EXTENSIONS = {
    TableQuery: ['.csv'],
    DocumentFinder: ['.txt', '.pdf', '.docx', '.doc', '.md', '.rst'],
    All: ['.csv', '.txt', '.pdf', '.docx', '.doc', '.md', '.rst', '.json', '.xml', '.html', '.htm', '.css', '.js', '.py', '.java', '.c', '.cpp', '.h', '.hpp', '.sql', '.yaml', '.yml', '.ini', '.cfg']
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const agentIdFromUrl = urlParams.get('agent_id');

    if (agentIdFromUrl) {
      setAgentId(agentIdFromUrl);
    } else {
      console.error("Agent ID is missing from URL");
      setError("Agent ID is missing. Please ensure you've created an agent first.");
    }

    fetchKnowledgeBases();

    const storedTools = JSON.parse(localStorage.getItem('selectedTools') || '[]');
    console.log('Stored tools:', storedTools);
    setSelectedTools(storedTools);
  }, [location]);

  const fetchKnowledgeBases = async () => {
    try {
      const bases = await getKnowledgeBases();
      setKnowledgeBases(bases);
    } catch (error) {
      console.error('Error fetching knowledge bases:', error);
      setError('Failed to fetch knowledge bases');
    }
  };

  const getUploadConfig = () => {
    if (selectedTools.includes('TableQuery') && selectedTools.includes('DocumentFinder')) {
      return {
        description: "Upload files to create a new knowledge base",
        acceptedExtensions: SUPPORTED_EXTENSIONS.All
      };
    } else if (selectedTools.includes('TableQuery')) {
      return {
        description: "Upload .CSV files to create a new Table Query Knowledge Base",
        acceptedExtensions: SUPPORTED_EXTENSIONS.TableQuery
      };
    } else if (selectedTools.includes('DocumentFinder')) {
      return {
        description: "Upload documents to create a new Knowledge Base for the Document Finder tool",
        acceptedExtensions: SUPPORTED_EXTENSIONS.DocumentFinder
      };
    } else {
      return {
        description: "Upload files to create a new knowledge base (Optional)",
        acceptedExtensions: SUPPORTED_EXTENSIONS.All
      };
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = [];
    const errors = [];
    const config = getUploadConfig();

    selectedFiles.forEach(file => {
      const extension = '.' + file.name.split('.').pop().toLowerCase();
      if (!config.acceptedExtensions.includes(extension)) {
        errors.push(`${file.name} has an unsupported file type for the selected tools.`);
      } else if (file.size > MAX_FILE_SIZE) {
        errors.push(`${file.name} exceeds the maximum file size of 10MB.`);
      } else {
        validFiles.push(file);
      }
    });

    if (validFiles.length + files.length > MAX_FILES) {
      errors.push(`You can only upload a maximum of ${MAX_FILES} files.`);
      setFiles(prevFiles => [...prevFiles, ...validFiles.slice(0, MAX_FILES - prevFiles.length)]);
    } else {
      setFiles(prevFiles => [...prevFiles, ...validFiles]);
    }

    if (errors.length > 0) {
      setError(errors.join(' '));
    }
  };

  const handleDeleteKnowledgeBase = async (knowledgeBaseId) => {
    const id = typeof knowledgeBaseId === 'object' && knowledgeBaseId.$oid 
      ? knowledgeBaseId.$oid 
      : knowledgeBaseId;
    console.log('Attempting to delete knowledge base with ID:', id);
    setIsDeletingKnowledgeBase(true);
    try {
      await deleteKnowledgeBase(id);
      setKnowledgeBases(prevBases => prevBases.filter(kb => {
        const kbId = kb._id.$oid || kb._id;
        return kbId !== id;
      }));
      if (selectedKnowledgeBase && (selectedKnowledgeBase.$oid === id || selectedKnowledgeBase === id)) {
        setSelectedKnowledgeBase(null);
      }
      setSuccessMessage('Knowledge base deleted successfully');
    } catch (error) {
      console.error('Error deleting knowledge base:', error);
      setError(`Failed to delete knowledge base: ${error.message}`);
    } finally {
      setIsDeletingKnowledgeBase(false);
    }
  };

  const handleNext = async () => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage('');
  
    if (!agentId) {
      setError("Agent ID is missing. Please ensure you've created an agent first.");
      setIsLoading(false);
      return;
    }
  
    try {
      if (selectedKnowledgeBase) {
        // Link the selected knowledge base to the agent
        await linkKnowledgeBaseToAgent(agentId, selectedKnowledgeBase);
        setSuccessMessage('Knowledge base linked successfully');
      } else if (isCreatingNew && files.length > 0) {
        // Proceed with file upload
        const formData = new FormData();
        formData.append('agent_id', agentId);
        files.forEach(file => formData.append('files', file));
  
        const response = await uploadFiles(formData);
        console.log('Upload response:', response);
        
        if (response && response.message && response.message.toLowerCase().includes('successfully')) {
          setSuccessMessage(response.message);
        } else {
          throw new Error(response.message || 'Unknown error occurred');
        }
      } else {
        // Allow proceeding without knowledge base or files for WebSearch tool
        setSuccessMessage('Proceeding without knowledge base or files.');
      }
  
      // Always proceed to the next step after a short delay
      setTimeout(() => {
        history.push(`/deploy?agent_id=${agentId}`);
      }, 2000);
    } catch (error) {
      console.error('Error processing request:', error);
      setError(`Failed to process: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleNext();
  };

  const handleKnowledgeBaseSelect = (knowledgeBaseId) => {
    setSelectedKnowledgeBase(knowledgeBaseId);
    setIsCreatingNew(false);
  };

  const handleCreateNew = () => {
    setSelectedKnowledgeBase(null);
    setIsCreatingNew(true);
  };

  const uploadConfig = getUploadConfig();

  return (
    <div className="bg-black min-h-screen p-8 font-['Arimo'] text-white">
      <div className="bg-gradient-to-b from-[#ff5757] via-[#8c52ff] to-black pt-16 pb-24">
        <div className="max-w-5xl mx-auto px-8">
          <h1 className="text-6xl font-bold mb-2">AI Assistant Builder</h1>
          <p className="text-2xl opacity-80">Create and deploy a new AI assistant</p>
        </div>
      </div>
      <div className="max-w-5xl mx-auto mt-8">
        <div className="flex justify-between mb-8">
          <div className="flex space-x-4">
            <div className="bg-[#141414] text-white px-4 py-2 rounded-full">
              1 Create
            </div>
            <div className="bg-[#1f1f1f] text-white px-4 py-2 rounded-full">
              2 Add files
            </div>
            <div className="bg-[#141414] text-white px-4 py-2 rounded-full">
              3 Deploy
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <h2 className="text-2xl font-bold mb-4">Select or Create Knowledge Base</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <CreateKnowledgeBaseItem 
                onClick={handleCreateNew} 
                isSelected={isCreatingNew}
              />
              {knowledgeBases.map((base) => (
                <KnowledgeBaseItem
                  key={base._id}
                  base={base}
                  isSelected={selectedKnowledgeBase === base._id}
                  onSelect={handleKnowledgeBaseSelect}
                  onDelete={handleDeleteKnowledgeBase}
                />
              ))}
            </div>
          </div>
  
          {isCreatingNew && (
            <div>
              <h2 className="text-2xl font-bold mb-4">Upload Files for New Knowledge Base</h2>
              <label htmlFor="file-upload" className="block text-lg mb-2">
                Choose files to upload
              </label>
              <input
                id="file-upload"
                type="file"
                multiple
                onChange={handleFileChange}
                className="w-full bg-[#1f1f1f] text-white px-4 py-3 rounded-lg"
                accept={uploadConfig.acceptedExtensions.join(',')}
              />
              <div className="mt-2 text-sm text-gray-400">
                <span>{files.length > 0 ? `${files.length} file(s) selected` : 'No file chosen'}</span>
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Max 100 files, 50MB per file. 
                Accepted file types: {uploadConfig.acceptedExtensions.join(', ')}
              </p>
            </div>
          )}
  
          {error && (
            <div className="bg-red-900 border border-red-700 text-white px-4 py-3 rounded-lg" role="alert">
              <strong className="font-bold">Error:</strong>
              <span className="block sm:inline"> {error}</span>
            </div>
          )}
  
          {fileValidationErrors.length > 0 && (
            <div className="bg-red-900 border border-red-700 text-white px-4 py-3 rounded-lg" role="alert">
              <strong className="font-bold">Validation Errors:</strong>
              <ul className="list-disc list-inside">
                {fileValidationErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )}
  
          {successMessage && (
            <div className="bg-green-900 border border-green-700 text-white px-4 py-3 rounded-lg" role="alert">
              <strong className="font-bold">Success:</strong>
              <span className="block sm:inline"> {successMessage}</span>
            </div>
          )}
  
          <div className="flex justify-end">

            <button
            type="submit"
            disabled={isLoading || (fileValidationErrors.length > 0) || isDeletingKnowledgeBase}
            className={`bg-[#4bb35d] hover:bg-[#45a456] text-white px-8 py-3 rounded-lg transition duration-300 ease-in-out ${
              (isLoading || fileValidationErrors.length > 0 || isDeletingKnowledgeBase) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isLoading ? 'Processing...' : 'Next'}
          </button>

          </div>
        </form>
  
        {isDeletingKnowledgeBase && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-[#1f1f1f] p-4 rounded-lg">
              <p>Deleting knowledge base...</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploadForm;


//<button
//type="submit"
//disabled={isLoading || (fileValidationErrors.length > 0) || isDeletingKnowledgeBase || (!selectedKnowledgeBase && !isCreatingNew)}
//className={`bg-[#4bb35d] hover:bg-[#45a456] text-white px-8 py-3 rounded-lg transition duration-300 ease-in-out ${
//  (isLoading || fileValidationErrors.length > 0 || isDeletingKnowledgeBase || (!selectedKnowledgeBase && !isCreatingNew)) ? 'opacity-50 cursor-not-allowed' : ''
//}`}
//>
//{isLoading ? 'Processing...' : 'Next'}
//</button>