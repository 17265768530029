import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchWithAuth, deleteAgent } from '../../utils/api';

const getModelMetrics = (agent) => {
  const isSonnet = agent.model_name.includes('claude-3-5-sonnet');
  const isHaiku = agent.model_name.includes('claude-3-haiku');
  
  return {
    reasoning: isSonnet ? 85 : (isHaiku ? 60 : 70),
    creativity: (agent.temperature || 0) * 100,
    context: ((agent.max_tokens || 0) / 8000) * 100,
    speed: isSonnet ? 70 : (isHaiku ? 90 : 80)
  };
};

const Dashboard = () => {
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await fetchWithAuth('/agents');
      setAgents(response);
    } catch (err) {
      setError('Failed to fetch agents. Please try again.');
      console.error('Error fetching agents:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAgent = (agentId) => {
    setAgents(agents.filter(agent => agent._id !== agentId));
  };

  const handleCreateNewAgent = () => {
    history.push('/create-agent');
  };

  const refreshDashboard = () => {
    fetchAgents();
  };

  if (isLoading) return <div className="flex justify-center items-center h-screen text-white">Loading...</div>;
  if (error) return <div className="flex justify-center items-center h-screen text-red-500">Error: {error}</div>;

  return (
    <div className="bg-black min-h-screen p-8 font-['Arimo'] text-white">
      <div className="bg-gradient-to-b from-[#ff5757] via-[#8c52ff] to-black pt-16 pb-24">
        <div className="max-w-5xl mx-auto px-8">
          <h1 className="text-6xl font-bold mb-2">AI Experts</h1>
          <p className="text-2xl opacity-80">Overview of the AI experts deployed</p>
        </div>
      </div>
      <div className="max-w-5xl mx-auto">
        <div className="space-y-6">
          <button
            onClick={handleCreateNewAgent}
            className="bg-[#141414] hover:bg-[#1f1f1f] text-white text-20px py-6 px-6 rounded-[30px] shadow-lg transition duration-300 ease-in-out transform hover:scale-105 w-full max-w-4xl mx-auto flex items-center justify-center"
          >
            <span className="mr-2">+</span> Create a new AI Expert
          </button>
          {agents.map((agent) => (
            <AgentCard 
              key={agent._id} 
              agent={agent} 
              onDelete={handleDeleteAgent} 
              refreshDashboard={refreshDashboard}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const AgentCard = ({ agent, onDelete, refreshDashboard }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(null);
  const metrics = getModelMetrics(agent);

  const handleDelete = async () => {
    try {
      if (!agent._id || !agent._id.$oid) {
        throw new Error('Invalid agent ID');
      }
      const agentIdString = agent._id.$oid;
      const response = await deleteAgent(agentIdString);
      
      if (response && response.message === "Agent deleted") {
        setDeleteStatus('success');
        setTimeout(() => {
          onDelete(agentIdString);
          refreshDashboard();
        }, 2000);
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error deleting agent:', error);
      setDeleteStatus('error');
      setTimeout(() => setDeleteStatus(null), 3000);
    }
  };

  const getActiveTools = (agent) => {
    const allTools = ['Web Search', 'Knowledge Base', 'Google Drive', 'Slack'];
    return allTools.filter(tool => {
      switch(tool) {
        case 'Web Search':
          return agent.tools.includes('WebSearch');
        case 'Knowledge Base':
          return agent.tools.includes('DocumentFinder') || agent.tools.includes('TableQuery');
        case 'Google Drive':
          return 'google_drive_connected' in agent;
        default:
          return false;
      }
    });
  };

  const activeTools = getActiveTools(agent);
  const isConnected = 'slack_workspaces' in agent;

  const getAvatarUrl = (agent) => {
    if (agent.avatar_url) {
      return agent.avatar_url;
    }
    return `https://api.dicebear.com/6.x/bottts/svg?seed=${agent.agent_name}`;
  };

  return (
    <div className="bg-[#121212] rounded-[30px] shadow-lg overflow-hidden p-6 text-white max-w-4xl mx-auto relative">
      <div className="absolute top-4 left-4 z-50">
        <button
          onClick={() => setShowMenu(!showMenu)}
          className="text-white text-xl focus:outline-none hover:text-gray-300 transition-colors duration-200"
        >
          ⋮
        </button>
        {showMenu && (
          <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-[#1f1f1f] ring-1 ring-black ring-opacity-5 z-50">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button
                onClick={handleDelete}
                className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-[#2f2f2f] transition-colors duration-200"
                role="menuitem"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
      {deleteStatus && (
        <div className={`absolute top-4 right-4 px-4 py-2 rounded-md ${
          deleteStatus === 'success' ? 'bg-[#4bb35d]' : 'bg-red-500'
        } text-white text-sm`}>
          {deleteStatus === 'success' ? 'Agent deleted successfully' : 'Failed to delete agent'}
        </div>
      )}
      <div className="flex">
        <div className="flex-shrink-0">
          <div className="relative">
            <img
              src={getAvatarUrl(agent)}
              alt={agent.agent_name}
              className="w-32 h-32 rounded-full object-cover"
            />
            <div className={`absolute bottom-3 right-3 w-4 h-4 rounded-full ${isConnected ? 'bg-[#4bb35d]' : 'bg-gray-500'} border border-white`}></div>
          </div>
        </div>
        <div className="ml-4 flex-grow">
          <h2 className="text-[20px]">{agent.agent_name}</h2>
          <p className="text-[15px]">{agent.description}</p>
          <p className="text-[15px] mt-1">
            Model: {agent.model_name}
          </p>

          <p className="text-[15px] mt-1">
          Exp: +{agent.experience || 0}
        </p>

          {activeTools.length > 0 && (
            <p className="text-[15px] mt-1">
              Tools: {activeTools.join(', ')}
            </p>
          )}
        </div>
        <div className="ml-4 w-1/3 space-y-2">
          {Object.entries(metrics).map(([key, value]) => (
            <div key={key} className="flex items-center">
              <span className="text-[15px] w-24 capitalize">{key}</span>
              <div className="flex-grow bg-gray-700 rounded-full h-2">
                <div
                  className="bg-[#4bb35d] rounded-full h-2"
                  style={{ width: `${value}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;