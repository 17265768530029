import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { completeGoogleDriveAuth } from '../../utils/api';
import { jwtDecode } from 'jwt-decode';

const GoogleDriveCallback = () => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleCallback = async () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      if (!code || !state) {
        console.error('Missing code or state in callback URL');
        setError('Invalid callback parameters');
        setIsLoading(false);
        return;
      }

      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Missing authentication token');
        setError('Authentication token is missing. Please log in again.');
        setIsLoading(false);
        setTimeout(() => history.push('/signin'), 3000);
        return;
      }

      try {
        const decodedState = jwtDecode(state);
        const agentId = decodedState.agent_id;

        if (!agentId) {
          console.error('Missing agent_id in decoded state');
          throw new Error('Agent ID is missing from the state token');
        }

        const response = await completeGoogleDriveAuth(code, state);

        if (response.message === "Google Drive connected successfully") {
          history.push(`/upload?google_drive_connected=true&agent_id=${agentId}`);
        } else {
          throw new Error(response.message || 'Failed to complete Google Drive authentication');
        }
      } catch (err) {
        console.error('Failed to complete Google Drive authentication', err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem('token');
          setError('Authentication failed. Please log in again.');
          setTimeout(() => history.push('/signin'), 3000);
        } else {
          setError(err.message || 'An error occurred during Google Drive authentication');
        }
      } finally {
        setIsLoading(false);
      }
    };

    handleCallback();
  }, [history, location]);

  if (isLoading) {
    return <div>Completing Google Drive authentication...</div>;
  }

  if (error) {
    return (
      <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
        <h2 className="text-2xl font-bold mb-4 text-red-600">Error during Google Drive authentication</h2>
        <p className="mb-4 text-gray-700">{error}</p>
        {error.includes('Please log in again') ? (
          <p>Redirecting to sign in page...</p>
        ) : (
          <button 
            onClick={() => history.push('/signin')}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Return to Sign In
          </button>
        )}
      </div>
    );
  }

  return null;
};

export default GoogleDriveCallback;