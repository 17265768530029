import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { completeSlackInstallation } from '../../utils/api';

const SlackCallback = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const handleCallback = async () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      if (!code || !state) {
        console.error('Invalid callback parameters');
        history.push('/error'); // Redirect to an error page
        return;
      }

      try {
        const response = await completeSlackInstallation(code, state);
        
        if (response && response.status === 'success') {
          // Automatically redirect to the dashboard
          //history.push('/dashboard');
          //history.push('/deploy');
          //history.push(`/deploy?slack_success=true&workspace_url=${encodeURIComponent(response.workspace_url)}&workspace_name=${encodeURIComponent(response.workspace_name)}`);
          history.push(`/deploy?slack_success=true&workspace_url=${encodeURIComponent(response.workspace_url)}&workspace_name=${encodeURIComponent(response.workspace_name)}&agent_id=${encodeURIComponent(response.agent_id)}`);

        } else {
          throw new Error('Unexpected response format');
        }
      } catch (err) {
        console.error('Error completing Slack installation:', err);
        history.push('/error'); // Redirect to an error page
      }
    };

    handleCallback();
  }, [location, history]);

  // Show a loading message while processing
  return <div>Connecting to Slack, please wait...</div>;
};

export default SlackCallback;