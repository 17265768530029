import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { createAgent, getAgentTemplates } from '../../utils/api';
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight } from 'react-feather';

const Chip = ({ label, selected, onClick }) => (
  <div
    className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-semibold mr-2 mb-2 cursor-pointer transition-colors ${
      selected ? 'bg-[#4bb35d] text-white' : 'bg-[#1f1f1f] text-gray-300 hover:bg-[#2f2f2f]'
    }`}
    onClick={onClick}
  >
    {label}
  </div>
);

const Slider = ({ value, onChange, min, max, step, labels }) => (
  <div className="w-full max-w-md">
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={(e) => onChange(parseFloat(e.target.value))}
      className="w-full h-2 bg-[#1f1f1f] rounded-lg appearance-none cursor-pointer accent-[#4bb35d]"
    />
    <div className="flex justify-between mt-2">
      {labels.map((label, index) => (
        <span key={index} className="text-sm text-gray-400">{label}</span>
      ))}
    </div>
  </div>
);

const AgentCreationForm = () => {
  const [agentName, setAgentName] = useState('');
  const [description, setDescription] = useState('');
  const [mission, setMission] = useState('');
  const [tools, setTools] = useState([]);
  const [fallbackMessage, setFallbackMessage] = useState('I cannot answer right now. Please try again later or use /reset_conversation in Slack.');
  const [creativity, setCreativity] = useState(0.2);
  const [reasoning, setReasoning] = useState(0.33);
  const [context, setContext] = useState(2048);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const templateRowRef = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  
  // Hidden fields (not exposed to user)
  const [provider, setProvider] = useState('anthropic');
  const [modelName, setModelName] = useState('claude-3-haiku-20240307');
  const [experience, setExperience] = useState(50);
  
  const history = useHistory();

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await getAgentTemplates();
      setTemplates(response);
    } catch (error) {
      console.error('Error fetching templates:', error);
      setError('Failed to fetch agent templates');
    }
  };

  const createAgentAndProceed = async (agentData) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await createAgent(agentData);
      if (response && response.agent_id) {
        return response;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      setError(`Failed to create agent: ${error.message || 'Please try again.'}`);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const agentData = {
      agent_name: agentName,
      description,
      mission,
      tools,
      experience,
      provider,
      fallback_message: fallbackMessage,
      temperature: creativity,
      model_name: modelName,
      max_tokens: context,
    };
    const response = await createAgentAndProceed(agentData);
    if (response && response.agent_id) {
      localStorage.setItem('currentAgentId', response.agent_id);
      localStorage.setItem('selectedTools', JSON.stringify(tools));
      history.push(`/upload?agent_id=${response.agent_id}`);
    }
  };

  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template._id.$oid);
    handleUseTemplate(template);
  };

  const handleUseTemplate = (template) => {
    setAgentName(template.agent_name);
    setDescription(template.description);
    setMission(template.mission);
    setProvider(template.provider);
    setModelName(template.model_name);
    setExperience(parseInt(template.experience));
    setTools(template.tools);

    // Set creativity (temperature)
    const templateCreativity = parseFloat(template.temperature);
    setCreativity(templateCreativity);

    // Set context (max_tokens)
    const templateContext = parseInt(template.max_tokens);
    setContext(templateContext);
    setFallbackMessage(template.fallback_message);
    
    // Update reasoning based on the model name
    if (template.model_name === "claude-3-haiku-20240307") {
      setReasoning(0);
    } else if (template.model_name === "claude-3-sonnet-20240229") {
      setReasoning(0.5);
    } else {
      setReasoning(1);
    }
    
    setShowAdvanced(true);
  };

  const handleToolToggle = (tool) => {
    setTools(prevTools => 
      prevTools.includes(tool) 
        ? prevTools.filter(t => t !== tool)
        : [...prevTools, tool]
    );
  };

  const handleReasoningChange = (value) => {
    if (value <= 0.33) {
      setReasoning(0);
      setModelName("claude-3-haiku-20240307");
    } else if (value <= 0.66) {
      setReasoning(0.5);
      setModelName("claude-3-sonnet-20240229");
    } else {
      setReasoning(1);
      setModelName("claude-3-5-sonnet-20240620");
    }
  };

  const getReasoningLabel = (value) => {
    if (value === 0) return "Low";
    if (value === 0.5) return "Medium";
    return "High";
  };

  const scrollTemplates = (direction) => {
    const container = templateRowRef.current;
    if (container) {
      const scrollAmount = 300; // Adjust this value to control scroll distance
      const newPosition = direction === 'left' 
        ? Math.max(scrollPosition - scrollAmount, 0)
        : Math.min(scrollPosition + scrollAmount, container.scrollWidth - container.clientWidth);
      
      container.scrollTo({
        left: newPosition,
        behavior: 'smooth'
      });
      setScrollPosition(newPosition);
    }
  };

  return (
    <div className="bg-black min-h-screen p-8 font-['Arimo'] text-white">
      <div className="bg-gradient-to-b from-[#ff5757] via-[#8c52ff] to-black pt-16 pb-24">
        <div className="max-w-5xl mx-auto px-8">
          <h1 className="text-6xl font-bold mb-2">AI Expert Builder</h1>
          <p className="text-2xl opacity-80">Create and deploy a new AI expert</p>
        </div>
      </div>
      <div className="max-w-5xl mx-auto mt-8">
        <div className="flex justify-between mb-8">
          <div className="flex space-x-4">
            <div className="bg-[#1f1f1f] text-white px-4 py-2 rounded-full">
              1 Create
            </div>
            <div className="bg-[#141414] text-white px-4 py-2 rounded-full">
              2 Add files
            </div>
            <div className="bg-[#141414] text-white px-4 py-2 rounded-full">
              3 Deploy
            </div>
          </div>
        </div>
  
        <div className="mb-8 relative">
          <h2 className="text-2xl font-bold mb-4">Choose a Template</h2>
          <div className="flex items-center">
            <button
              onClick={() => scrollTemplates('left')}
              className="bg-[#1f1f1f] p-2 rounded-full mr-2"
              aria-label="Scroll left"
            >
              <ChevronLeft size={24} />
            </button>
            <div
              ref={templateRowRef}
              className="flex overflow-x-auto scrollbar-hide space-x-4 py-4"
              style={{ scrollBehavior: 'smooth' }}
            >
              {templates.map((template) => (
                <div
                  key={template._id.$oid}
                  className={`bg-[#1f1f1f] p-4 rounded-lg flex-shrink-0 cursor-pointer transition-all duration-300 ${
                    selectedTemplate === template._id.$oid ? 'border-2 border-[#4bb35d]' : ''
                  }`}
                  style={{ width: '300px', transition: 'border-color 0.2s ease-in-out' }}
                  
                  onClick={() => handleSelectTemplate(template)}

                >
                  <h3 className="text-xl font-bold mb-2">{template.agent_name}</h3>
                  <p className="text-sm opacity-80 mb-4">{template.description}</p>
                </div>
              ))}
            </div>
            <button
              onClick={() => scrollTemplates('right')}
              className="bg-[#1f1f1f] p-2 rounded-full ml-2"
              aria-label="Scroll right"
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
  
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Customize your Agent</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="agentName" className="block text-lg mb-2">Agent Name</label>
              <input
                id="agentName"
                type="text"
                value={agentName}
                onChange={(e) => setAgentName(e.target.value)}
                className="w-full bg-[#1f1f1f] text-white px-4 py-3 rounded-lg"
                required
              />
            </div>
            <div>
              <label htmlFor="description" className="block text-lg mb-2">Description</label>
              <input
                id="description"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full bg-[#1f1f1f] text-white px-4 py-3 rounded-lg"
                required
              />
            </div>
            <div>
              <label htmlFor="mission" className="block text-lg mb-2">Mission</label>
              <textarea
                id="mission"
                value={mission}
                onChange={(e) => setMission(e.target.value)}
                className="w-full bg-[#1f1f1f] text-white px-4 py-3 rounded-lg h-32"
                required
              />
            </div>
            <div>
              <label className="block text-lg mb-2">Tools</label>
              <div className="flex flex-wrap">
                {['WebSearch', 'DocumentFinder', 'TableQuery', 'DataScience', 'Calculator'].map((tool) => (
                  <Chip
                    key={tool}
                    label={tool}
                    selected={tools.includes(tool)}
                    onClick={() => handleToolToggle(tool)}
                  />
                ))}
              </div>
            </div>
  
            <div>
              
        <button
          type="button"
          onClick={() => setShowAdvanced(!showAdvanced)}
          className="flex items-center text-[#4bb35d] hover:text-[#45a456] transition duration-300 ease-in-out"
        >
          {showAdvanced ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          <span className="ml-2">{showAdvanced ? 'Hide' : 'Show'} Advanced Settings</span>
        </button>
      </div>
  
            {showAdvanced && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="creativity" className="block text-lg mb-2">Creativity</label>
                  <Slider
                    value={creativity}
                    onChange={setCreativity}
                    min={0.2}
                    max={0.6}
                    step={0.01}
                    labels={['Low', 'Medium', 'High']}
                  />
                  <div className="text-sm text-gray-400 mt-1">
                    Current: {creativity.toFixed(2)}
                  </div>
                </div>
                <div>
                  <label htmlFor="reasoning" className="block text-lg mb-2">Reasoning</label>
                  <Slider
                    value={reasoning}
                    onChange={handleReasoningChange}
                    min={0}
                    max={1}
                    step={0.5}
                    labels={['Low', 'Medium', 'High']}
                  />
                  <div className="text-sm text-gray-400 mt-1">
                    Current: {getReasoningLabel(reasoning)}
                  </div>
                </div>
                <div>
                  <label htmlFor="context" className="block text-lg mb-2">Context</label>
                  <Slider
                    value={context}
                    onChange={setContext}
                    min={1024}
                    max={4096}
                    step={1}
                    labels={['Low', 'Medium', 'High']}
                  />
                  <div className="text-sm text-gray-400 mt-1">
                    Current: {context}
                  </div>
                </div>
                <div>
                  <label htmlFor="fallbackMessage" className="block text-lg mb-2">Fallback Message</label>
                  <input
                    id="fallbackMessage"
                    type="text"
                    value={fallbackMessage}
                    onChange={(e) => setFallbackMessage(e.target.value)}
                    className="w-full bg-[#1f1f1f] text-white px-4 py-3 rounded-lg"
                  />
                </div>
              </div>
            )}
  
            {error && <p className="text-red-500">{error}</p>}
            <div className="flex justify-end">
              <button
                type="submit"
                disabled={isLoading}
                className="bg-[#4bb35d] hover:bg-[#45a456] text-white px-8 py-3 rounded-lg transition duration-300 ease-in-out"
              >
                {isLoading ? 'Creating...' : 'Create Agent'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AgentCreationForm;
