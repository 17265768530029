import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connectSlack } from '../../utils/api';

const DeploymentForm = ({ agentId: propAgentId }) => {
  const [agentId, setAgentId] = useState(propAgentId);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [workspaceInfo, setWorkspaceInfo] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const urlAgentId = urlParams.get('agent_id');
    const slackSuccess = urlParams.get('slack_success');
    const workspaceUrl = urlParams.get('workspace_url');
    const workspaceName = urlParams.get('workspace_name');
    const slackError = urlParams.get('slack_error');

    // Set agentId from URL if available, otherwise keep the prop value
    if (urlAgentId) {
      setAgentId(urlAgentId);
    }

    if (slackSuccess === 'true' && workspaceUrl && workspaceName) {
      setWorkspaceInfo({
        url: decodeURIComponent(workspaceUrl),
        name: decodeURIComponent(workspaceName)
      });
    } else if (slackError) {
      setError(decodeURIComponent(slackError));
    }
  }, [location, propAgentId]);

  const handleConnectToSlack = async () => {
    if (!agentId) {
      setError('Agent ID is missing. Please ensure you have created an agent first.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const result = await connectSlack(agentId);
      if (result.authorization_url) {
        window.location.href = result.authorization_url;
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (err) {
      console.error('Error connecting to Slack:', err);
      setError(`Failed to connect to Slack: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-black min-h-screen p-8 font-['Arimo'] text-white">
      <div className="bg-gradient-to-b from-[#ff5757] via-[#8c52ff] to-black pt-16 pb-24">
        <div className="max-w-5xl mx-auto px-8">
          <h1 className="text-6xl font-bold mb-2">AI Expert Builder</h1>
          <p className="text-2xl opacity-80">Create and deploy a new AI expert</p>
        </div>
      </div>
      <div className="max-w-5xl mx-auto mt-8">
        <div className="flex justify-between mb-8">
          <div className="flex space-x-4">
            <div className="bg-[#141414] text-white px-4 py-2 rounded-full">
              1 Create
            </div>
            <div className="bg-[#141414] text-white px-4 py-2 rounded-full">
              2 Add files
            </div>
            <div className="bg-[#1f1f1f] text-white px-4 py-2 rounded-full">
              3 Deploy
            </div>
          </div>
        </div>
        <div className="space-y-6">
          <h2 className="text-2xl font-bold">Deploy Agent to Slack</h2>
          {error && (
            <div className="bg-red-900 border border-red-700 text-white px-4 py-3 rounded-lg" role="alert">
              <strong className="font-bold">Error:</strong>
              <span className="block sm:inline"> {error}</span>
            </div>
          )}
          {workspaceInfo ? (
            <div className="space-y-4">
              <p className="text-xl">Your AI agent is now ready to use in your Slack workspace!</p>
              <a 
                href={workspaceInfo.url} 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-[#4bb35d] hover:bg-[#45a456] text-white px-6 py-3 rounded-lg inline-block transition duration-300 ease-in-out"
              >
                Open {workspaceInfo.name}
              </a>
              <div className="mt-6">
                <h3 className="text-xl font-bold mb-2">Getting Started</h3>
                <ol className="list-decimal list-inside space-y-2">
                  <li>Go to the App tab in your Slack workspace and add Everlearning.ai agents.</li>
                  <li>Start chatting with your agent from the App’s Home tab.</li>
                  <li>Spread the word! Share these instructions with your colleagues if you'd like them to use your agent too.</li>
                </ol>
              </div>
            </div>
          ) : (
            <button 
              onClick={handleConnectToSlack} 
              disabled={isLoading || !agentId}
              className={`bg-[#4bb35d] hover:bg-[#45a456] text-white px-8 py-3 rounded-lg transition duration-300 ease-in-out ${
                isLoading || !agentId ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isLoading ? 'Processing...' : 'Connect to Slack'}
            </button>
          )}
          {isLoading && <p className="text-gray-300">Processing... This may take a moment.</p>}
          {!agentId && <p className="text-yellow-600">No agent ID detected. Please ensure you've created an agent first.</p>}
          {!workspaceInfo && (
            <div className="mt-4">
              <span className="text-white">Don't have a Slack account yet? </span>
              <a 
                href="https://slack.com/get-started" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-[#4bb35d] hover:bg-[#45a456] transition duration-300 ease-in-out"
              >
                Sign up
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeploymentForm;