import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { signIn } from '../../utils/api';
import { useAuth } from '../../AuthContext'; 

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await signIn(email, password);
      login(response.user, response.access_token);
      history.push('/dashboard');
    } catch (err) {
      setError(err.data?.detail || 'An error occurred during sign in');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-black min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 font-['Arimo'] text-white">
      <div className="max-w-md w-full space-y-8">
        <div className="flex justify-center">
          <img 
            src="https://storage.googleapis.com/everlearningai-tmp-bucket/logos/logo-portrait.png" 
            alt="Logo" 
            className="w-48 h-auto mb-8"
          />
        </div>
        <h2 className="mt-6 text-center text-3xl font-bold">
          Sign in to the agent builder
        </h2>
        {error && <p className="text-red-500 text-center">{error}</p>}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label htmlFor="email-address" className="block text-lg mb-2">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="w-full bg-[#1f1f1f] text-white px-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4bb35d] focus:border-[#4bb35d]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-lg mb-2">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="w-full bg-[#1f1f1f] text-white px-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4bb35d] focus:border-[#4bb35d]"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full bg-[#4bb35d] hover:bg-[#45a456] text-white px-8 py-3 rounded-lg transition duration-300 ease-in-out"
            >
              {isLoading ? 'Signing In...' : 'Sign In'}
            </button>
          </div>
        </form>
        <div className="text-center">
          <p>
            Don't have an account?{' '}
            <Link to="/signup" className="text-[#4bb35d] hover:text-[#45a456] transition duration-300 ease-in-out">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;